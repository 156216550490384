<template>
      <form class="space-y-6 w-full pb-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="col-span-1 md:col-span-2">
            <h2 class="text-lg dark:text-gray-300 font-semibold">Nosūtīt rēķinu e-pastā</h2>
          </div>
          <div class="col-span-1">
            <Input
              name="email"
              placeholder="Saņēmēju e-pasti (atdalīt ar komatu)"
              v-model="form.email"
              :errors="errors.email ? errors.email : responseErrors"
            />
          </div>
        </div>

        <div class="flex justify-end">
          <button
            type="button"
            class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2"
            @click="hideForm"
          >
            Atcelt
          </button>

          <template v-if="form.email">
            <template v-if="!loading">
              <button
                type="button"
                class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                @click.prevent="submit"
              >
                Nosūtīt
              </button>
            </template>
            <template v-else>
              <Loading />
            </template>
          </template>
        </div>
      </form>
</template>

<script>
import Input from "@/components/Components/Input";
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
    name: "SendInvoiceByEmail",
    components: {
      Input,
      Loading,
    },
    data() {
      return {
        form: {
          email: '',
          document_type: 'invoice'
        },
        responseErrors: []
      }
    },
    props: {
      item: {
        type: Object,
        required: true,
      }
    },
    mounted() {
      this.form.email = this.item.invoice_emails
    },
    computed: {
      ...mapGetters({
        loading: 'loading',
        errors: 'errors',
      }),
      formValidation() {
        return {
          email: {
            rules: ['required']
          }
        }
      }
    },
    methods: {
      hideForm() {
        this.$store.dispatch("removeAllFormsForDisplay");
      },
      submit() {
        this.$Progress.start();
        if (Validation(this.formValidation, this.form)) {
          this.$store
            .dispatch("sendInvoiceByEmailToAddress", {
              invoiceId: this.item.id,
              address: this.form.email,
              document_type: this.form.document_type,
            }).then(() => {
              this.$store.dispatch("getSingleInvoice", this.item.id);
            }).catch(error => {
              this.responseErrors = error.response.data.errors.address
            });
        } else {
          this.$Progress.fail();
        }
      },
    }
}
</script>

<style>

</style>